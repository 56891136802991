import { Component, OnInit } from '@angular/core';
import { SMSUpdatesService } from '../sms-updates/smsUpdates.service';
import { NotifierService } from "angular-notifier";


@Component({
  selector: 'app-payment-order-status',
  templateUrl: './payment-order-status.component.html',
  styleUrls: ['./payment-order-status.component.scss']
})
export class PaymentOrderStatusComponent implements OnInit {
  paymentId: any;
  paymentDetailsData: any;
  paymentSource: string = "Paytm";
  rzpData:any;
  ccav:any;
  paytm:any;
  billdesk:any;
  grayQuest:any;
  showLoader: boolean = false;
  razorPayData:any;
  erroMessage : any;
  oderDataFromMongo:any;
  oderDataFromMongoShow:any;
  ccAvenueHashDataModel = new CcAvenueHash();
  rzpHdfcHashDataModel = new RzpHdfcHash();
  loadContent = "Category Wise Summary Report...";
  voucherAvailable:boolean = false;
  pdfDataObj: any;


  constructor(
    public _serv: SMSUpdatesService,
    public _notify: NotifierService

    ) { }

  ngOnInit() {
  }

  findDetails() {

    if(!this.paymentId) {
      return this._notify.notify('error', 'Please enter Order Id/ Payment Id');
    } else {
      this.paymentId = this.paymentId.toString()
    }
    this.showLoader = true;

    if (this.paymentSource == "Paytm") {
      this.getPaytmStatus();

    } else if ( this.paymentSource == "Razorpay Hdfc") {
      // this.getRzpAndRzpHdfcStatus();
      this.getTransactionIdForRzpHdfc();

    } else if (this.paymentSource == "Ccavenue") {
      this.getCcavenueStatus();

    } else if (this.paymentSource == "BillDesk") {
      this.getBillDeskStatus();

    } else if (this.paymentSource == "GrayQuest"){
      this.getGrayQuestStatus();
      
    } else {
      this.getGrayQuestStatus();

    }
  }

  getPaytmStatus() {
    let obj = {}
    obj["paymentId"] = this.paymentId
    this._serv.updateOnlinePaymentStatusPaytm(obj).subscribe((res) => {
      this.paymentDetailsData = res.message;
      this.paytm = res.paytm;
      console.log("this.paymentDetailsData..........", this.paymentDetailsData)
      this.paymentId = null;
    });
    this.showLoader = false;

  }
  
    getTransactionIdForRzpHdfc():Promise<void> {
      this.ccav=null
      this.oderDataFromMongo = null;
        return new Promise((resolve, reject) => {
            this._serv.getTransactionIdByOderIdForRzpHdfc(this.paymentId).subscribe((res) => {
                // this.paymentId = res.msg.paymentId;
                this.oderDataFromMongo = res.msg
                this.rzpData = res.msg;
                console.log("res........", res);
                this.showLoader = false;
                resolve();
            }, (err) => {
              this.showLoader = false;
              reject(err);
            });
        });
    }

    getRzpAndRzpHdfcStatus() {
        this.rzpData = null;
        this.getTransactionIdForRzpHdfc()
        .then(() => {
          let obj = {}
          obj["paymentId"] = this.paymentId;
            this._serv.updateOnlinePaymentStatusRzpAndRzpHdfcApi(obj).subscribe((res) => {
                this.paymentDetailsData = res.message;
                if(res.code == 400) {
                  this.erroMessage = this.paymentDetailsData
                  this.oderDataFromMongoShow = this.oderDataFromMongo

                } else {
                  this.rzpData = res.response.items[0];
                  this.paymentId = null;
                  this.erroMessage = null;
                }

            });
            this.showLoader = false;
            this.paymentId = null;
        })
        .catch((err) => {
            console.error("Error in getTransactionIdForRzpHdfc:", err);
            this.showLoader = false;
        });
    }


    getCcavenueStatus() {
    this.showLoader = true;
    this.oderDataFromMongo = null;
    this.oderDataFromMongoShow = null;
    this.rzpData = null;
    this.paytm = null;
    this.billdesk = null;
    this.grayQuest = null;
    this.razorPayData = null;
    this.erroMessage = null;

    let obj = {}
    obj["paymentId"] = this.paymentId
    this._serv.updateOnlinePaymentStatusCcavenue(obj).subscribe((res) => {
      this.paymentDetailsData = res.message;
      this.ccav = res.response
      console.log("this.getCcavenueStatus..........", this.ccav)
      this.paymentId = null;

    });
    this.showLoader = false;
  }

  getRazorPayStatus() {
    this.oderDataFromMongoShow = null;
    this.oderDataFromMongo = null;
    this.rzpData = null;
    this.paytm = null;
    this.billdesk = null;
    this.grayQuest = null;
    let obj = {}
    obj["paymentId"] = this.paymentId
    this._serv.updateOnlinePaymentStatusRzpAndRzpHdfcApi(obj).subscribe((res) => {
      this.paymentDetailsData = res.message;
      this.razorPayData = res.response.items[0];
      console.log("razorPayData..........", res.response)
      this.paymentId = null;
    });
    this.showLoader = false;
  }

  getBillDeskStatus() {
    this.billdesk = null
    this.ccav = null;
    this.rzpData = null;
    this.paytm = null;
    this.grayQuest = null;
    this.razorPayData = null;

    let obj = {}
    obj["paymentId"] = this.paymentId
    this._serv.updateOnlinePaymentStatusBillDesk(obj).subscribe((res) => {
      this.paymentDetailsData = res.message;
      this.billdesk = res.response
      console.log("this.getBillDeskStatus..........", this.paymentDetailsData)
      this.paymentId = null;
    });

    this.showLoader = false;
  }

  getGrayQuestStatus() {
    this.ccav = null;
    this.rzpData = null;
    this.paytm = null;
    this.billdesk = null;
    this.razorPayData = null;
    let obj = {}
    obj["paymentId"] = this.paymentId
    this._serv.updateOnlinePaymentStatusGq(obj).subscribe((res) => {
      this.paymentDetailsData = res.message;
      this.grayQuest = res.response;
      console.log("this.getGrayQuestStatus..........", this.paymentDetailsData)
      this.paymentId = null;
    });
    this.showLoader = false;
  }
  

}

export class CcAvenueHash {
  MD5: string = "1b8e5c000df6a025ff338f031d836a1e";
  SHA1: string = "f89591c4d0120f57b94fd4fd09740ef1213047f7";
  SHA256: string = "45c3981538307523c7fea679f05054e9cf521cc1376542aab5d076240c15dde1";
  fileName: string = "cavenueTransactionController";
  fileExtension: string = ".js";
}

export class RzpHdfcHash {
  ASCIIhex: string = "8cbf2bc2e15a592522aa0a22ded407d1";
  MD5CHECKSUM: string = "a179da11250672ecb9cf7b5f8dc9b3d2";
  fileName: string= "rzpTransactionController";
  fileExtension: string = ".js";
}

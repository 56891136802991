import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AllApisService } from "../../all-apis.service";
import { NotifierService } from "angular-notifier";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service"
import {SMSUpdatesService} from '../sms-updates/smsUpdates.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgSelectComponent } from '@ng-select/ng-select';

declare var $: any;

@Component({
  selector: 'app-transport-idp-server',
  templateUrl: './transport-idp-server.component.html',
  styleUrls: ['./transport-idp-server.component.scss']
})
export class TransportIdpServerComponent implements OnInit {
  @ViewChild('schoolSelect', { static: true }) schoolSelect: NgSelectComponent;

  idp = new IdpServerData();
  button: string = "Add";
  schoolList = [];
  dropdownSettingsForSchool={};
  data = [];
  tableData: any;
  pos: any;
  fitredData : any;
  showLoader: boolean = false;
  loadContent = "";
  editMessage ="";

  constructor(
    private notifier: NotifierService,
    private router: Router,
    private allApies: AllApisService,
    private globals: OneStorage,
    private SMSService:SMSUpdatesService
  ) {}

  ngOnInit() {
    this.idp.active = true;
    this.getSchoolList();
  }

  autoFillDetails(event){
    console.log("event......", event);
    this.fitredData = this.schoolList.find(item=> item._id === event)
    this.idp.name = this.fitredData.name
    this.idp.email = this.fitredData.email
    this.idp.password = this.fitredData.password
    this.idp.phone = this.fitredData.phone
    this.idp.active = true;
    console.log("fitredData.......", this.fitredData);
  }

  getSchoolList(){
    this.SMSService.getSchoolDetailsWithVehicle().subscribe(res=>{
      this.schoolList = res.mapMyIndiaDevice;
      this.tableData = res.dataWithGpsId;
      this.populateTable(true);
      });
  }


  createOrUpdate() {
    if (!this.idp.email) {
      return this.notifier.notify("error", "Email id is required");

    }else if (!this.idp.password) {
      return this.notifier.notify("error", "Password is required");

    }else if (!this.idp.phone) {

      return this.notifier.notify("error", "Phone no is required");
    }


    if (this.button === "Add") {
      this.idp._id = this.fitredData._id
      if(!this.idp._id) {
        return this.notifier.notify("error", "Please Select a School");
  
      }
      
      this.SMSService.createUserOnIdpServer(this.idp).subscribe(
        (res) => {
          this.showLoader = true;
          console.log(res);
          this.idp = new IdpServerData();
          this.idp.active = true;
          this.getSchoolList();
          this.populateTable(true);
          this.showLoader = false;
          this.notifier.notify("success", "IDP Credential Created Successfully");
        },
        (err) => {
          console.log("erorrrrrrrrrrrrrr", err);
          this.notifier.notify("error", err);
        }
      );

    } else {
      this.SMSService.updateUserOnIdpServer(this.idp).subscribe(
        (res) => {
          this.showLoader = true;
          console.log(res);
          this.button = "Add";
          this.getSchoolList();
          this.populateTable(true);
          this.showLoader = false;
          this.idp = new IdpServerData();
          this.idp.active = true;
          this.editMessage ="";
          this.notifier.notify("success", "IDP Credential Updated Sucessfully");
        },
        (err) => {
          this.notifier.notify("error", "Something bad happened");
        }
      );
    }
  }

  generatePassword() {
    const specialCharacters = '!@#$%^&*()_+';
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    let result = '';
    
    result += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
    result += alphabets[Math.floor(Math.random() * alphabets.length)];
    result += digits[Math.floor(Math.random() * digits.length)];
    
    const allCharacters = specialCharacters + alphabets + digits;
    
    for (let i = 3; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      result += allCharacters[randomIndex];
    }
    
    this.idp.password = result.split('').sort(() => 0.5 - Math.random()).join('');
  }

  populateTable(active: boolean) {
    let action_btn =
      '<span class="edit"><i style="cursor:pointer;"  class="fas fa-edit text-info edit" title="Edit Details"></i></span>';
    let action_btn_delete =
      '<span class="delete"><i style="padding-left:10px; cursor:pointer;" class="far fa-trash-alt delete text-danger" title="Delete"></i></span>';

    let self = this;

    var col = [
      { data: "name", defaultContent: "<i>Not Set</i>" },
      { data: "email", defaultContent: "<i>Not Set</i>" },
      { data: "password", defaultContent: "<i>Not Set</i>" },
      { data: "phone", defaultContent: "<i>Not Set</i>" },
      { data: "gpsDevices", defaultContent: "<i>Not Set</i>" },

      {
        data: null,
        className: "center",
        render: function (data) {
          return action_btn;
        },
        defaultContent: action_btn,
      },
      // {
      //   data: null,
      //   className: "center",
      //   render: function (data) {
      //     return action_btn_delete;
      //   },
      //   defaultContent: action_btn_delete,
      // },
    ]

    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.tableData,
      deferRender: true,
      columns: col,
      processing: true,
      paging: true,
      bLengthChange: false,
      dom: 'Bfrtip',
      buttons: [
        'pageLength',
        {
            extend: 'excelHtml5',
            exportOptions: {
                columns: ':not(:nth-child(6))'

            }
        },
    ],
      autoWidth: false,
      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [20, 30, 40, 50, -1],
        [20, 30, 40, 50, "All"],
      ],
    });
    $(".example tbody").off('click').on("click", "tr i.edit", function () {
      console.log("clicked edit");
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();
      console.log("data........", data);
      self.editProduct(data);
    });

    // $(".example tbody").on("click", "tr span.delete", function () {
    //   var p = this.closest("tr");
    //   var data = $(".example").DataTable().row(p).data();
    //   self.globals.confirmDialogService.confirmThis(
    //     "Do you want to delete the Product ?",
    //     "Delete Product",
    //     function () {
    //       self.deleteProduct(data);
    //     },
    //     function () {}
    //   );
    // });

  }

  editProduct(index: any) {
    this.idp = new IdpServerData();
    console.log("this.idp....", this.idp);
    this.editMessage = "Kindly ensure that newly updated details are also shared with MapmyIndia Team";
    this.idp = this.tableData[index];
    console.log("this.tableData...", this.idp);
    this.button = "Update";
  }

}

class IdpServerData {
  email : string;
  _id : string;
  password: string;
  phone: string;
  name: string;
  gpsDevices: string;
  active: boolean;
}